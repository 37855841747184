<template>
  <div class="preview-each-settings-block">
    <GrayBlockContainer>
      <div class="flex flex-col" style="gap: 12px">
        <div v-for="(item, idx) in displayData" :key="idx" class="flex items-start gap-[20px]">
          <div v-if="item.label">
            {{ item.label }}
          </div>
          <div class="flex flex-col">
            <div v-for="(text, textIdx) in item.contnet" :key="textIdx" class="text-gray-60">
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </GrayBlockContainer>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { toNumber, find, join, get, includes } from 'lodash'

export default defineComponent({
  name: 'PreviewEachWeekSettingsBlock',
  components: {
    GrayBlockContainer,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const formatPeriodsPreviewText = (periodSettings) => {
      const contents = []
      for (const period of periodSettings) {
        const { start, end } = period
        contents.push(`${start || '-'} ~ ${end || '-'}`)
      }
      return join(contents, '、')
    }

    const formatAllowResourcesPreviewText = (allowResources) => {
      if (allowResources.includes('pointCard') && allowResources.includes('punchCard')) {
        return '開放次卡與點數卡'
      }
      if (allowResources.includes('punchCard')) {
        return '開放次卡'
      }
      if (allowResources.includes('pointCard')) {
        return '開放點數卡'
      }
      return ''
    }

    const weekDays = [
      { label: '周一', value: 1 },
      { label: '周二', value: 2 },
      { label: '周三', value: 3 },
      { label: '周四', value: 4 },
      { label: '周五', value: 5 },
      { label: '周六', value: 6 },
      { label: '周日', value: 0 },
    ]

    const formatMaxDurationPreviewText = (setting) => {
      if (get(setting, 'entitlementApplyMode') === 'allEntitlement') return '限定單次進場最高時數上限'
      return '部分進場權益限定單次進場最高時數上限'
    }
    const formatOvertimeExitPreviewText = (setting) => {
      if (get(setting, 'entitlementApplyMode') === 'allEntitlement') return '開放超時出場'
      return '部分進場權益開放超時出場'
    }
    const handleMaxDurationAndOvertimeExit = ({ setting, textLines }) => {
      const enableMaxDuration = get(setting, 'enableMaxDuration')
      const enableOvertimeExit = get(setting, 'enableOvertimeExit')
      if (enableMaxDuration) {
        const maxDurationSetting = get(setting, 'maxDurationSetting')
        textLines.push(formatMaxDurationPreviewText(maxDurationSetting))
      }
      if (enableOvertimeExit) {
        const overtimeExitSetting = get(setting, 'overtimeExitSetting')
        textLines.push(formatOvertimeExitPreviewText(overtimeExitSetting))
      }
    }

    const handlePeriod = ({ setting, textLines, periodSetting, type }) => {
      const periodAllowResources = []
      if (get(setting, 'enablePointCardEntry')) periodAllowResources.push('pointCard')
      if (get(setting, 'enablePunchCardEntry')) periodAllowResources.push('punchCard')

      const formatedPeriods = type === 'eachTime' ? formatPeriodsPreviewText([periodSetting]) : formatPeriodsPreviewText(periodSetting)
      if (periodAllowResources.length > 1) {
        textLines.push(`${formatAllowResourcesPreviewText(periodAllowResources)}進場：${formatedPeriods}`)
      } else {
        textLines.push(`僅${formatAllowResourcesPreviewText(periodAllowResources)}進場：${formatedPeriods}`)
      }
    }

    const displayData = computed(() => {
      const dataList = []
      const eachWeekSetting = get(props.configData, 'eachDaySettings')

      eachWeekSetting.sort((a, b) => {
        // week === 0 排在最後
        const aWeek = toNumber(a.week)
        const bWeek = toNumber(b.week)

        // 依照 1,2,3,4,5,6,0 排序
        if (aWeek === 0) return 1
        if (bWeek === 0) return -1
        return aWeek - bWeek
      })

      for (const weekDaySetting of eachWeekSetting) {
        const textLines = []
        const enable = get(weekDaySetting, 'enable')
        const setting = get(weekDaySetting, 'data')
        const weekDayLabel = get(find(weekDays, { value: toNumber(weekDaySetting.week) }), 'label')
        if (!enable) {
          textLines.push('不開放進場')
          dataList.push({
            label: weekDayLabel,
            contnet: textLines,
          })
          continue
        }
        const timeApplyMode = get(setting, 'timeApplyMode')
        if (timeApplyMode === 'allTime') {
          const allTimePeriods = get(setting, 'periodSettings')
          handlePeriod({
            textLines,
            setting: {
              enablePointCardEntry: includes(get(setting, 'allPeriodAssets.accept'), 'pointCard'),
              enablePunchCardEntry: includes(get(setting, 'allPeriodAssets.accept'), 'punchCard'),
            },
            periodSetting: allTimePeriods,
          })
        } else if (timeApplyMode === 'eachTime') {
          const eachPeriodSetting = get(setting, 'periodSettings')
          for (const eachPeriod of eachPeriodSetting) {
            handlePeriod({
              textLines,
              setting: {
                enablePointCardEntry: includes(get(eachPeriod, 'assets.accept'), 'pointCard'),
                enablePunchCardEntry: includes(get(eachPeriod, 'assets.accept'), 'punchCard'),
              },
              periodSetting: eachPeriod,
              type: 'eachTime',
            })
          }
        }

        // 限制進場時數 && 超時出場
        handleMaxDurationAndOvertimeExit({
          setting: {
            enableMaxDuration: get(setting, 'maxDurationSetting.enable'),
            enableOvertimeExit: get(setting, 'overtimeExitSetting.enable'),
          },
          textLines,
        })

        dataList.push({
          label: weekDayLabel,
          contnet: textLines,
        })
      }
      return dataList
    })
    return { displayData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
