import { reactive, computed, ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { nanoid } from 'nanoid'
import { forEach, map, includes, compact, toNumber, get, isEmpty, find } from 'lodash'
import { GetBranchStore } from '@/api/branchStore'
import { useFetch } from '@/use/fetch'
import { useShop } from './shop'

const weekDays = [
  { label: '周一', value: 1 },
  { label: '周二', value: 2 },
  { label: '周三', value: 3 },
  { label: '周四', value: 4 },
  { label: '周五', value: 5 },
  { label: '周六', value: 6 },
  { label: '周日', value: 0 },
]

export const useEditEntryControl = () => {
  const branchStores = ref([])
  const settings = reactive({
    enable: false,
    branchApplyMode: 'allBranch', // allBranch┃eachBranch

    allBranchStoreSettings: {
      weekApplyMode: 'allWeek', // allWeek┃eachWeek
      allDaySettings: {},
      eachDaySettings: [],
    },
    eachBrachStoreSettings: [],
  })

  const formRefList = ref([])

  const toFormData = (settingData) => {
    if (!settingData || isEmpty(settingData)) return {}
    const allPeriodAssetsAccept = []
    if (get(settingData, 'allTimeSetting.enablePointCardEntry')) allPeriodAssetsAccept.push('pointCard')
    if (get(settingData, 'allTimeSetting.enablePunchCardEntry')) allPeriodAssetsAccept.push('punchCard')
    const formData = {
      allPeriodAssets: {
        accept: allPeriodAssetsAccept,
        punchCardIds: get(settingData, 'allTimeSetting.punchCardIds'),
        pointCardIds: get(settingData, 'allTimeSetting.pointCardIds'),
        punchCards: [],
        pointCards: [],
        pointCardRate: get(settingData, 'allTimeSetting.pointCardRate'),
      },
      periodSettings: map(settingData.eachTimeSettings, (item) => {
        const accept = []
        if (get(item, 'setting.enablePointCardEntry')) accept.push('pointCard')
        if (get(item, 'setting.enablePunchCardEntry')) accept.push('punchCard')
        return {
          start: get(item, 'period.start'),
          end: get(item, 'period.end'),
          assets: {
            accept,
            pointCardIds: get(item, 'setting.pointCardIds'),
            pointCardRate: get(item, 'setting.pointCardRate'),
            punchCardIds: get(item, 'setting.punchCardIds'),
          },
        }
      }),
      overtimeExitSetting: {
        enable: settingData.enableOvertimeExit,
        entitlementApplyMode: get(settingData, 'overtimeExitSetting.entitlementApplyMode'),
        allSetting: {
          rule: get(settingData, 'overtimeExitSetting.allEntitlementSetting.allowRule'),
          maxDuration: get(settingData, 'overtimeExitSetting.allEntitlementSetting.allowMinutes'),
        },
        eachSetting: get(settingData, 'overtimeExitSetting.eachEntitlementSettings'),
      },
      maxDurationSetting: {
        enable: settingData.enableMaxDuration,
        entitlementApplyMode: get(settingData, 'maxDurationSetting.entitlementApplyMode'),
        allSetting: {
          maxDuration: get(settingData, 'maxDurationSetting.allEntitlementSetting.maxDuration'),
        },
        eachSetting: get(settingData, 'maxDurationSetting.eachEntitlementSettings'),
      },
      timeApplyMode: get(settingData, 'timeApplyMode'),
    }
    return formData
  }

  const initSettings = async (configData) => {
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    formRefList.value = []

    await fetchAll(GetBranchStore, {
      shopId: shopId.value,
    }, (res) => {
      branchStores.value = res
    })

    const callList = []

    // 所有門市 - 所有日設定
    callList.push(async () => {
      const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
      newSettingModel(toFormData(get(configData, 'entrySetting.allBranchSetting.allWeekSetting')), '所有門市 - 所有日設定')
      settings.allBranchStoreSettings.allDaySettings = formData
      formRefList.value.push({
        id: formData.value.id,
        formModel,
      })
    })

    // 所有門市 - 逐日設定
    callList.push(async () => {
      for (let count = 0; count < 7; count++) {
        const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
        const weekDayLabel = find(weekDays, { value: count }).label
        newSettingModel(toFormData(get(configData, `entrySetting.allBranchSetting.eachWeekSettings[${count}].setting`)), `所有門市 - ${weekDayLabel}`)
        const enable = get(configData, `entrySetting.allBranchSetting.eachWeekSettings[${count}].enable`)
        settings.allBranchStoreSettings.eachDaySettings.push({
          enable,
          week: count,
          data: formData,
        })
        formRefList.value.push({
          id: formData.value.id,
          formModel,
        })
      }
    })

    // 個別門市
    callList.push(async () => {
      const subCallList = []
      for (const branchStore of branchStores.value) {
        const originEachBranchSettings = get(configData, 'entrySetting.eachBranchSettings')
        const existBranchStoreSetting = find(originEachBranchSettings, {
          branchId: branchStore.id,
        })
        const weekApplyMode = get(existBranchStoreSetting, 'setting.weekApplyMode', 'allWeek')
        settings.eachBrachStoreSettings.push({
          branchStore,
          settings: {
            enable: false,
            weekApplyMode,
            allDaySettings: {},
            eachDaySettings: [],
          },
        })
      }

      // 個別門市 - 所有日設定
      subCallList.push(async () => {
        forEach(settings.eachBrachStoreSettings, async (storeSetting) => {
          const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
          const originEachBranchSettings = get(configData, 'entrySetting.eachBranchSettings')
          const existBranchStoreSetting = find(originEachBranchSettings, {
            branchId: storeSetting.branchStore.id,
          })

          if (existBranchStoreSetting) {
            newSettingModel(toFormData(existBranchStoreSetting.setting.allWeekSetting), `${storeSetting.branchStore.name} - 所有日設定`)
            storeSetting.settings.enable = get(existBranchStoreSetting, 'enable', false)
          } else {
            newSettingModel({}, `${storeSetting.branchStore.name} - 所有日設定`)
            storeSetting.settings.enable = false
          }

          storeSetting.settings.allDaySettings = formData
          formRefList.value.push({
            id: formData.value.id,
            formModel,
          })
        })
      })

      // 個別門市 - 逐日設定
      subCallList.push(async () => {
        forEach(settings.eachBrachStoreSettings, async (storeSetting) => {
          const existBranchStoreSetting = find(get(configData, 'entrySetting.eachBranchSettings'), {
            branchId: storeSetting.branchStore.id,
          })

          for (let count = 0; count < 7; count++) {
            const originWeekDaySetting = find(get(existBranchStoreSetting, 'setting.eachWeekSettings'), {
              week: count.toString(),
            })
            const { formModel, formData, newSettingModel } = useEntryControlSettingModel()
            const enable = get(originWeekDaySetting, 'enable', false)
            const weekDayLabel = find(weekDays, { value: count }).label
            newSettingModel(toFormData(originWeekDaySetting.setting), `${storeSetting.branchStore.name} - ${weekDayLabel}`)
            storeSetting.settings.eachDaySettings.push({
              enable,
              week: count,
              data: formData,
            })
            formRefList.value.push({
              id: formData.value.id,
              formModel,
            })
          }
        })
      })

      await Promise.all(subCallList.map((call) => call()))
    })

    await Promise.all(callList.map((call) => call()))

    settings.enable = get(configData, 'enable', false)
    settings.branchApplyMode = get(configData, 'entrySetting.branchApplyMode', 'allBranch')
    settings.allBranchStoreSettings.weekApplyMode = get(configData, 'entrySetting.allBranchSetting.weekApplyMode', 'allWeek')
  }

  return { settings, initSettings, formRefList, branchStores }
}

export const useEntryControlSettingModel = () => {
  const formData = ref({
    allDaySettings: {},
    eachDaySettings: [],
  })

  const newSettingModel = ({ id, allPeriodAssets, periodSettings, overtimeExitSetting, maxDurationSetting, timeApplyMode }, formName) => {
    formData.value = new EntryControlSettingModel({
      id,
      allPeriodAssets,
      periodSettings,
      overtimeExitSetting,
      maxDurationSetting,
      timeApplyMode,
    })
    formData.value.name = formName
  }

  const formRules = computed(() => {
    return {
      allDaySettings: {
        overtimeExitSetting: { required },
        maxDurationSetting: { required },
      },
      eachDaySettings: {
        required,
      },
    }
  })

  const formModel = useVuelidate(formRules, { formData })

  return { formModel, formData, newSettingModel }
}

export class EntryControlSettingModel {
  constructor ({ id, timeApplyMode, allPeriodAssets, periodSettings, overtimeExitSetting, maxDurationSetting, punchCardIds, pointCards }) {
    this.id = id || nanoid(6)
    this.timeApplyMode = timeApplyMode || 'allTime'
    this.allPeriodAssets = allPeriodAssets || {
      accept: [],
      punchCardIds: [],
      pointCardIds: [],
      punchCards: [],
      pointCards: [],
      pointCardRate: undefined,
    }
    this.periodSettings = periodSettings || [
      {
        start: undefined,
        end: undefined,
        assets: {
          accept: [],
          punchCardIds: [],
          pointCardIds: [],
          pointCardRate: undefined,
        },
      },
    ]
    // 超時進場設定
    this.overtimeExitSetting = overtimeExitSetting || {
      enable: false,
      entitlementApplyMode: 'allEntitlement',
      allSetting: {
        rule: undefined,
        maxDuration: undefined,
      },
      eachSetting: [],
    }
    // 但次進場時數上限設定
    this.maxDurationSetting = maxDurationSetting || {
      enable: false,
      entitlementApplyMode: 'allEntitlement',
      allSetting: {
        maxDuration: undefined,
      },
      eachSetting: [],
    }
  }

  addPeriodSetting () {
    this.periodSettings.push({
      start: undefined,
      end: undefined,
      assets: {
        accept: [],
        punchCardIds: [],
        pointCardIds: [],
      },
    })
  }

  removePeriodSetting (index) {
    this.periodSettings.splice(index, 1)
  }

  formatSettingData () {
    return {
      timeApplyMode: this.timeApplyMode,
      allTimeSetting: {
        enablePunchCardEntry: this.allPeriodAssets.accept.includes('punchCard'),
        punchCardIds: this.allPeriodAssets.punchCardIds,
        enablePointCardEntry: this.allPeriodAssets.accept.includes('pointCard'),
        pointCardIds: this.allPeriodAssets.pointCardIds,
        pointCardRate: toNumber(this.allPeriodAssets.pointCardRate) || 0,
      },
      allTimePeriods: map(this.periodSettings, (period) => {
        return { start: period.start, end: period.end }
      }),
      eachTimeSettings: compact(map(this.periodSettings, (period) => {
        return {
          period: {
            start: period.start,
            end: period.end,
          },
          setting: {
            enablePunchCardEntry: includes(period.assets.accept, 'punchCard'),
            punchCardIds: period.assets.punchCardIds,
            enablePointCardEntry: includes(period.assets.accept, 'pointCard'),
            pointCardIds: period.assets.pointCardIds,
            pointCardRate: toNumber(period.assets.pointCardRate) || 0,
          },
        }
      })),

      enableMaxDuration: get(this.maxDurationSetting, 'enable', false),
      maxDurationSetting: {
        entitlementApplyMode: get(this.maxDurationSetting, 'entitlementApplyMode', 'allEntitlement'),
        allEntitlementSetting: {
          maxDuration: toNumber(this.maxDurationSetting.allSetting.maxDuration) || 0,
        },
        eachEntitlementSettings: map(this.maxDurationSetting.eachSetting, (setting) => {
          return {
            type: setting.type,
            originId: setting.originId,
            enable: setting.enable,
            setting: {
              maxDuration: toNumber(setting.setting.maxDuration) || 0,
            },
          }
        }),
      },
      enableOvertimeExit: get(this.overtimeExitSetting, 'enable', false),
      overtimeExitSetting: {
        entitlementApplyMode: get(this.overtimeExitSetting, 'entitlementApplyMode', 'allEntitlement'),
        allEntitlementSetting: {
          allowRule: this.overtimeExitSetting.allSetting.rule,
          allowMinutes: toNumber(this.overtimeExitSetting.allSetting.maxDuration) || 0,
        },
        eachEntitlementSettings: map(this.overtimeExitSetting.eachSetting, (setting) => {
          return {
            type: setting.type,
            originId: setting.originId,
            enable: setting.enable,
            setting: {
              rule: setting.rule,
              allowMinutes: toNumber(setting.maxDuration) || 0,
            },
          }
        }),
      },
    }
  }
}

export const entitlementApplyMode = {
  allEntitlement: 'allEntitlement',
  eachEntitlement: 'eachEntitlement',
}

export const timeApplyMode = {
  allTime: 'allTime',
  eachTime: 'eachTime',
}

export const weekApplyMode = {
  allWeek: 'allWeek',
  eachWeek: 'eachWeek',
}

export const overtimeExitRule = {
  allowMinutes: 'allowMinutes',
  allowForever: 'allowForever',
}
