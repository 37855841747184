<template>
  <div class="punch-card-binding-area">
    <p class="area-title">綁定次卡</p>
    <div class="flex items-center gap-[12px] justify-between">
      <div v-show="!selected.length" class="area-content">尚未編輯</div>
      <div v-show="selected.length" class="area-content">{{ displayData }}</div>
      <BaseElButton class="edit-btn" size="small" plain @click="modal.selector = true">編輯</BaseElButton>
    </div>

    <PunchCardAdvanceSelect
      v-if="modal.selector"
      :data="assetsData.punchCard"
      :selectedData="selected"
      multiple
      @confirm="onUpdate"
      @close="modal.selector = false"
    />
  </div>
</template>

<script>
import PunchCardAdvanceSelect from '@/components/Select/PunchCardAdvanceSelect.vue'
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { map, get, join } from 'lodash'

export default defineComponent({
  name: 'PunchCardBindingArea',
  components: {
    PunchCardAdvanceSelect,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const assetsData = inject('assetsData')

    const syncData = useVModel(props, 'selected', emit)
    const modal = reactive({
      selector: false,
    })

    const onUpdate = (newData) => {
      syncData.value = newData
    }

    const displayData = computed(() => {
      return join(map(props.selected, (id) => {
        return get(assetsData.punchCard.find((item) => item.id === id), 'name')
      }), '、')
    })

    return { modal, onUpdate, assetsData, displayData }
  },
})

</script>

<style scoped lang="postcss">
.punch-card-binding-area {
    @apply border border-gray-30 rounded-[12px] p-[20px];
}
.area-title {
    @apply font-medium text-sub;
}
.area-content {
    @apply text-gray-60 text-sub;
}
.edit-btn {
  @apply border border-primary-100 text-sm px-[12px] py-[3.5px];
}
</style>
