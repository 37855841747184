<template>
  <BaseDialog
    title="確認後將影響會員進場時段"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <div class="mb-[40px]">
      若變更門市開放進場時段，將影響持有點數卡 / 次卡的會員進場時段。
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConfirmUpdateEntryControlSettingModal',
  components: {
    BaseDialog,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    return {}
  },
})

</script>

<style scoped lang="postcss">

</style>
