<template>
  <div class="section-block">
    <SectionTitle :title="`${branchStore.name} 進場時段與收費模式`" hideBtn />
    <BaseElForm label-position="top" class="pt-[20px]">
      <BaseElFormItem label="開放進場">
        <BaseElSwitch
          v-model="enable"
          active-text="是"
          inactive-text="否"
          @change="onEnableChange"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div v-show="syncFormData.enable">
      <div class="flex justify-between">
        <BaseElRadioGroup v-model="syncFormData.weekApplyMode">
          <BaseElRadio label="allWeek">一週七天套用相同時段</BaseElRadio>
          <!-- <BaseElRadio label="eachWeek">一週七天個別設定時段</BaseElRadio> -->
        </BaseElRadioGroup>

        <BaseElButton
          v-if="syncFormData.weekApplyMode === weekApplyMode.eachWeek"
          plain
          style="padding: 0.5px 12px; font-size: 12px; line-height: 17.38px;"
          @click="showPreviewSetting = !showPreviewSetting"
        >
          <div class="flex items-center font-medium" style="gap: 2px">
            展開設定
            <span class="material-icons text-sub">{{ showPreviewSetting ? 'expand_more' : 'expand_less' }} </span>
          </div>
        </BaseElButton>
      </div>

      <PreviewEachWeekSettingsBlock
        v-if="syncFormData.weekApplyMode === weekApplyMode.eachWeek && showPreviewSetting"
        style="margin-top: 20px"
        :configData="formData"
      />

      <!-- 周一到周日選擇按鈕組 -->
      <BaseElButtonGroup v-if="syncFormData.weekApplyMode === weekApplyMode.eachWeek && !showPreviewSetting" class="w-full flex pt-[20px]">
        <BaseElButton
          v-for="(weekDay) in weekDays"
          :key="`weekDay-${weekDay.value}`"
          class="w-full"
          :class="{ 'active-day': selectWeekDay === weekDay.value }"
          @click="selectWeekDay = weekDay.value"
        >
          {{ weekDay.label }}
        </BaseElButton>
      </BaseElButtonGroup>

      <div v-show="syncFormData.weekApplyMode === weekApplyMode.allWeek" class="pt-[20px]">
        <EntryControlAssetsAndPeriodSettingsBlock
          :data.sync="syncFormData.allDaySettings"
          blockType="eachBranch"
          :periodType="weekApplyMode.allWeek"
        />
      </div>

      <div v-show="syncFormData.weekApplyMode === weekApplyMode.eachWeek && !showPreviewSetting" class="pt-[20px]">
        <EntryControlAssetsAndPeriodSettingsBlock
          v-for="(daySetting, idx) in syncFormData.eachDaySettings"
          v-show="selectWeekDay === idx + 1 || (selectWeekDay === 0 && idx === 6)"
          :key="daySetting.data.id"
          :data.sync="daySetting.data"
          blockType="eachBranch"
          :enable="daySetting.enable"
          :periodType="weekApplyMode.eachWeek"
        >
          <BaseElFormItem label="開放進場">
            <BaseElSwitch
              v-model="daySetting.enable"
              active-text="是"
              inactive-text="否"
            />
          </BaseElFormItem>
        </EntryControlAssetsAndPeriodSettingsBlock>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import EntryControlAssetsAndPeriodSettingsBlock from './EntryControlAssetsAndPeriodSettingsBlock.vue'
import { weekApplyMode } from '@/use/useEditEntryControl'
import { useVModel } from '@vueuse/core'
import { computed, defineComponent, onMounted, ref } from 'vue'
import PreviewEachWeekSettingsBlock from './PreviewEachWeekSettingsBlock.vue'

export default defineComponent({
  name: 'EachBranchStoreSettingBlock',
  components: {
    SectionTitle,
    EntryControlAssetsAndPeriodSettingsBlock,
    PreviewEachWeekSettingsBlock,
  },
  props: {
    branchStore: {
      type: Object,
      default: () => ({}),
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const enable = ref(false)
    const selectWeekDay = ref(1)
    const showPreviewSetting = ref(true)
    const weekDays = [
      { label: '周一', value: 1 },
      { label: '周二', value: 2 },
      { label: '周三', value: 3 },
      { label: '周四', value: 4 },
      { label: '周五', value: 5 },
      { label: '周六', value: 6 },
      { label: '周日', value: 0 },
    ]
    const syncFormData = useVModel(props, 'formData', emit)
    const displayData = computed(() => {
      return {}
    })
    const onEnableChange = (toggle) => {
      syncFormData.value.enable = toggle
    }
    onMounted(() => {
      enable.value = syncFormData.value.enable
    })
    return {
      enable,
      displayData,
      syncFormData,
      weekDays,
      weekApplyMode,
      selectWeekDay,
      onEnableChange,
      showPreviewSetting,
    }
  },
})

</script>

<style lang="postcss" scoped>
.active-day {
  @apply border border-primary-100 bg-primary-100 text-white;
}

::v-deep .el-button {
  @apply shadow-none;
}
</style>
