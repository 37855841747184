<template>
  <div class="period-range-select">
    <ElInputWrapper class="w-full">
      <BaseElSelect
        v-model="syncStart"
        clearable
        placeholder="開始時間"
        @change="syncEnd = ''"
      >
        <BaseElSelectOption
          v-for="time in startTimeOptions"
          :key="time.value"
          :label="time.label"
          :value="time.value"
        />
      </BaseElSelect>
    </ElInputWrapper>
    <p>至</p>
    <ElInputWrapper class="w-full">
      <BaseElSelect
        v-model="syncEnd"
        clearable
        placeholder="結束時間"
        :disabled="!syncStart"
      >
        <BaseElSelectOption
          v-for="time in endTimeOptions"
          :key="time.value"
          :label="time.label"
          :value="time.value"
        />
      </BaseElSelect>
    </ElInputWrapper>
  </div>
</template>

<script>
import { plusZero, timeToNumber } from '@/utils/time'
import { useVModel } from '@vueuse/core'
import { computed, defineComponent, reactive } from 'vue'
import ElInputWrapper from './ElInputWrapper.vue'
import { filter } from 'lodash'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
export default defineComponent({
  name: 'PeriodRangeSelect',
  components: {
    ElInputWrapper,
  },
  props: {
    start: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      default: '',
    },
    crossDay: {
      type: Boolean,
      default: false,
    },
    timeStep: {
      type: Number,
      default: 30,
    },
    startErrorMsg: String,
    endErrorMsg: String,
  },
  setup (props, { emit }) {
    const formData = reactive({
      start: props.start,
      end: props.end,
    })
    const rules = computed(() => {
      return {
        start: {
          required,
        },
        end: {
          required,
        },
      }
    })
    const v$ = useVuelidate(rules, formData)
    const syncStart = useVModel(props, 'start', emit)
    const syncEnd = useVModel(props, 'end', emit)
    const times = computed(() => {
      const timesArray = []
      const limit = props.crossDay ? 48 * 2 + 1 : 24 * 2 + 1

      for (let i = 0; i < limit; i++) {
        const hour = plusZero(Math.floor(i / 2))
        const minute = (i % 2) * props.timeStep
        let label = `${hour}:${plusZero(minute)}`

        if (props.crossDay && hour >= 24) {
          label = `${plusZero(hour - 24)}:${plusZero(minute)} (隔日)`
        }

        timesArray.push({
          label,
          value: `${hour}:${plusZero(minute)}`,
        })
      }

      return timesArray
    })

    const startTimeOptions = computed(() => {
      if (props.crossDay) {
        const limit = 24 * 60
        return filter(times.value, (time) => {
          return timeToNumber(time.value) < limit
        })
      }
      return times.value
    })

    const endTimeOptions = computed(() => {
      // 大於開始時間
      return filter(times.value, (time) => {
        return timeToNumber(time.value) > timeToNumber(syncStart.value)
      })
    })

    return { times, syncStart, syncEnd, startTimeOptions, endTimeOptions, v$ }
  },
})

</script>

<style scoped lang="postcss">
.period-range-select {
    @apply flex items-center gap-[8px];
}
</style>
