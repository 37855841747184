<template>
  <div class="section-block">
    <SectionTitle title="基本設定" hideBtn />

    <BaseElForm label-position="top" class="pt-[20px]">
      <BaseElFormItem label="開放進場">
        <BaseElSwitch
          v-model="syncEnable"
          inactive-text="否"
          active-text="是"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div class="flex flex-col gap-[8px]">
      <BaseElRadioGroup v-show="syncEnable" v-model="syncValue">
        <BaseElRadio label="allBranch">所有門市套用相同設定</BaseElRadio>
        <!-- <BaseElRadio label="eachBranch">個別設定門市</BaseElRadio> -->
      </BaseElRadioGroup>

      <div v-show="syncValue === 'allBranch'">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[8px]">
            <div class="flex items-center gap-[8px]">
              <p class="font-medium">所有門市一覽</p>
              <TipInfo :size="16">
                如需增加門市，請至「基本參數設定 > 門市設定」
              </TipInfo>
            </div>
            <div class="text-gray-80">
              {{ displayData.storesName }}
            </div>
          </div>
        </GrayBlockContainer>
      </div>
    </div>
  </div>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import TipInfo from '@/components/TipInfo.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { useVModel } from '@vueuse/core'
import { map, join } from 'lodash'

import { computed, defineComponent, inject, ref } from 'vue'
export default defineComponent({
  name: 'BasicSetting',
  components: {
    SectionTitle,
    GrayBlockContainer,
    TipInfo,
  },
  props: {
    value: {
      type: String,
      default: 'allBranch',
    },
    enable: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const branchStores = inject('branchStores')
    const syncValue = useVModel(props, 'value', emit)
    const syncEnable = useVModel(props, 'enable', emit)

    const displayData = computed(() => {
      return {
        storesName: join(map(branchStores.value, 'name'), '、'),
      }
    })

    return { syncValue, syncEnable, branchStores, displayData }
  },
})

</script>

<style lang="postcss" scoped>
</style>
