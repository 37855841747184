<template>
  <BaseDialog
    title="限定單次進場最高時數上限"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div class="pb-[40px]">
      <p>以下僅有「所有時段開放進場權益」綁定的進場權益</p>

      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <div class="overflow-y-auto" style="max-height: 500px;">
          <BaseElFormItem v-for="item in formData" :key="item.originId" :prop="`${item.originId}.setting.maxDuration`">
            <div class="flex flex-col">
              <div class="flex items-center justify-between">
                <p>{{ item.name }}</p>
                <BaseElCheckbox v-model="item.enable" @change="resetField(`${item.originId}.setting.maxDuration`)">
                  限定單次進場最高時數上限
                </BaseElCheckbox>
              </div>
              <BaseElInput
                v-model="item.setting.maxDuration"
                type="number"
                :disabled="!item.enable"
                placeholder="請輸入數字"
              />
            </div>
          </BaseElFormItem>
        </div>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { computed, defineComponent, inject, onBeforeMount, reactive, ref, set } from 'vue'
import { map, find, forEach, values, toNumber, get } from 'lodash'
import { isDigitRules, noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'AssetsMaxDurationBindingModal',
  components: {
    BaseDialog,
  },
  props: {
    activeAssets: {
      type: Object,
      default: () => ({
        punchCardIds: [],
        pointCardIds: [],
      }),
    },
    settings: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const assetsData = inject('assetsData')
    const formRef = ref(null)
    const formData = reactive({})
    const formRules = computed(() => {
      const rules = {}

      forEach(formData, (item, key) => {
        if (item.enable) {
          set(rules, `${key}.setting.maxDuration`, [noEmptyRules(), isDigitRules()])
        }
      })

      return rules
    })

    const onConfirm = async () => {
      const data = values(formData)
      const valid = await formUtils.checkForm(formRef.value)
      if (!valid) return
      emit('confirm', data)
      emit('close')
    }

    const resetField = (field) => {
      formUtils.clearValidate(formRef.value, [field])
    }

    const syncFormData = () => {
      const settings = JSON.parse(JSON.stringify(props.settings))
      for (const setting of settings) {
        if (get(formData, setting.originId)) {
          formData[setting.originId].enable = setting.enable
          formData[setting.originId].setting.maxDuration = setting.setting.maxDuration
        }
      }
    }

    const init = async () => {
      const callList = []

      callList.push(async () => {
        for (const id of props.activeAssets.punchCardIds) {
          const punchCard = find(assetsData.punchCard, { id })
          set(formData, id, {
            type: 'punchCard',
            originId: id,
            name: get(punchCard, 'name', '-'),
            enable: false,
            setting: {
              maxDuration: undefined,
            },
          })
        }
      })

      callList.push(async () => {
        for (const id of props.activeAssets.pointCardIds) {
          const pointCard = find(assetsData.pointCard, { id })
          set(formData, id, {
            type: 'pointCard',
            originId: id,
            name: get(pointCard, 'name', '-'),
            enable: false,
            setting: {
              maxDuration: undefined,
            },
          })
        }
      })

      await Promise.all(callList.map((fn) => fn()))
    }

    onBeforeMount(async () => {
      await init()
      syncFormData()
    })

    return { formData, onConfirm, formRef, formRules, resetField, assetsData }
  },
})

</script>

<style scoped lang="postcss">

</style>
