<template>
  <div class="binding-area">
    <div class="flex items-center gap-[12px] justify-between">
      <div class="area-content">
        <slot />
      </div>
      <BaseElButton size="small" plain @click="$emit('edit')">編輯</BaseElButton>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'BindingArea',
  setup (props, { emit }) {
    return {}
  },
})

</script>

<style scoped lang="postcss">
.binding-area {
    @apply border border-gray-30 rounded-[12px] p-[20px];
}

.area-content {
@apply text-gray-60 text-sub;
}
</style>
