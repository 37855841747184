<template>
  <main class="edit-entry-control-time-and-price-settings">
    <PageTitle
      title="編輯進場時與收費模式"
      icon="chevron_left"
      hideBtn
      @iconClick="goBack"
    />

    <div v-show="loading.init" v-loading="loading.init">
      初始化中
    </div>

    <BasicSetting
      :value.sync="settings.branchApplyMode"
      :enable.sync="settings.enable"
      @enableChange="onEnableChange"
    />

    <div v-if="loaded && settings.enable && settings.branchApplyMode === 'allBranch'">
      <AllBranchStoreSettingBlock
        :formData.sync="settings.allBranchStoreSettings"
      />
    </div>

    <div v-if="loaded && settings.enable && settings.branchApplyMode === 'eachBranch'" class="flex flex-col gap-[30px]">
      <EachBranchStoreSettingBlock
        v-for="branchSetting in settings.eachBrachStoreSettings"
        :key="branchSetting.id"
        :formData.sync="branchSetting.settings"
        :branchStore="branchSetting.branchStore"
      />
    </div>

    <ConfirmUpdateEntryControlSettingModal
      v-if="modal.confirmUpdate"
      :loading="loading.submit"
      @close="modal.confirmUpdate = false"
      @confirm="onConfirmSubmit"
    />

    <PageFixedFooter
      :confirmLoading="loading.submit"
      @confirm="onSubmit"
      @cancel="onCancel"
    />
  </main>
</template>

<script>
import { computed, defineComponent, nextTick, onBeforeMount, onMounted, provide, reactive, ref } from 'vue'
import BasicSetting from './components/BasicSetting.vue'
import AllBranchStoreSettingBlock from './components/AllBranchStoreSettingBlock.vue'
import EachBranchStoreSettingBlock from './components/EachBranchStoreSettingBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import { useRouter } from 'vue-router/composables'
import { useEditEntryControl } from '@/use/useEditEntryControl'
import { map, toString, some, filter, forEach } from 'lodash'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { GetPunchCard } from '@/api/punchCard'
import { GetPointCard } from '@/api/pointCard'
import { UpdateEntryControlConfig, GetEntryControlConfig } from '@/api/entryControl'
import formUtils from '@/utils/form'
import ConfirmUpdateEntryControlSettingModal from './components/ConfirmUpdateEntryControlSettingModal.vue'

export default defineComponent({
  name: 'EditEntryControlTimeAndPriceSetting',
  components: {
    PageTitle,
    BasicSetting,
    AllBranchStoreSettingBlock,
    EachBranchStoreSettingBlock,
    ConfirmUpdateEntryControlSettingModal,
  },
  setup () {
    const router = useRouter()
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const configData = ref({})
    const formRefList = ref([])
    const modal = reactive({
      confirmUpdate: false,
    })
    const { settings, initSettings, branchStores } = useEditEntryControl()
    const loaded = ref(false)
    const loading = reactive({
      init: false,
      submit: false,
    })

    const assetsData = reactive({
      punchCard: [],
      pointCard: [],
    })

    const submitPayload = computed(() => {
      const data = {
        enable: settings.enable,
        entrySetting: {
          branchApplyMode: settings.branchApplyMode,
          allBranchSetting: {
            weekApplyMode: settings.allBranchStoreSettings.weekApplyMode,
            allWeekSetting: settings.allBranchStoreSettings.allDaySettings.formatSettingData(),
            eachWeekSettings: map(settings.allBranchStoreSettings.eachDaySettings, (day) => {
              return {
                enable: day.enable,
                week: toString(day.week),
                setting: day.data.formatSettingData(),
              }
            }),
          },
          eachBranchSettings: map(settings.eachBrachStoreSettings, (branchSetting) => {
            return {
              branchId: branchSetting.branchStore.id,
              enable: branchSetting.settings.enable,
              setting: {
                weekApplyMode: branchSetting.settings.weekApplyMode,
                allWeekSetting: branchSetting.settings.allDaySettings.formatSettingData(),
                eachWeekSettings: map(branchSetting.settings.eachDaySettings, (day) => {
                  return {
                    enable: day.enable,
                    week: toString(day.week),
                    setting: day.data.formatSettingData(),
                  }
                }),
              },
            }
          }),
        },
        entitlementSelectionOrder: configData.value.entitlementSelectionOrder,
      }

      return data
    })
    const goBack = () => {
      router.push({
        name: 'EntryControlSettings',
      })
    }

    const onEnableChange = (toggle) => {
      settings.enable = toggle
    }

    const getAssetsData = async () => {
      fetchAll(GetPunchCard, { shopId: shopId.value }, (res) => {
        assetsData.punchCard = res
      })
      fetchAll(GetPointCard, { shopId: shopId.value }, (res) => {
        assetsData.pointCard = res
      })
    }

    const validateForms = async () => {
      if (!settings.enable) return true
      const curBranchApplyMode = settings.branchApplyMode

      const formRefIds = []

      if (curBranchApplyMode === 'allBranch') {
        const weekApplyMode = settings.allBranchStoreSettings.weekApplyMode
        if (weekApplyMode === 'allWeek') {
          formRefIds.push(settings.allBranchStoreSettings.allDaySettings.id)
        } else if (weekApplyMode === 'eachWeek') {
          forEach(settings.allBranchStoreSettings.eachDaySettings, (item) => {
            formRefIds.push(item.data.id)
          })
        }
      } else if (curBranchApplyMode === 'eachBranch') {
        forEach(settings.eachBrachStoreSettings, (item) => {
          if (item.settings.enable) {
            const weekApplyMode = item.settings.weekApplyMode
            if (weekApplyMode === 'allWeek') {
              formRefIds.push(item.settings.allDaySettings.id)
            } else if (weekApplyMode === 'eachWeek') {
              forEach(item.settings.eachDaySettings, (day) => {
                formRefIds.push(day.data.id)
              })
            }
          }
        })
      }

      const checkFormList = []
      // formRefList  formRefIds
      const formRefs = filter(formRefList.value, (item) => {
        return formRefIds.includes(item.id)
      })
      const formsCheckResult = {}
      formRefs.forEach((item) => {
        checkFormList.push(async () => {
          const checkResult = await formUtils.checkForm(item.formRef || item.formRef?.value)
          formsCheckResult[item.id] = {
            pass: checkResult,
            ref: item.formRef || item.formRef?.value,
            name: item.name,
          }
          return checkResult
        })
      })
      await Promise.all(checkFormList.map((fn) => fn()))
      return formsCheckResult
    }

    const onConfirmSubmit = async () => {
      if (loading.submit) return
      loading.submit = true
      const validResults = await validateForms()

      const errorForms = filter(validResults, { pass: false })
      if (errorForms.length > 0) {
        const errorFormNames = map(errorForms, (item) => `「${item.name}」`).join('、')
        window.$message.warning(`${errorFormNames}，資料有誤或未填寫完整，請檢查`)
        loading.submit = false
        return
      }

      const [res, err] = await UpdateEntryControlConfig({
        shopId: shopId.value,
        ...submitPayload.value,
      })
      loading.submit = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
      goBack()
    }

    const onSubmit = async () => {
      modal.confirmUpdate = true
    }

    const getConfigData = async () => {
      const [res, err] = await GetEntryControlConfig({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
    }

    const onCancel = () => {
      router.push({
        name: 'EntryControlSettings',
      })
    }

    provide('assetsData', assetsData)
    provide('formRefList', formRefList)
    provide('branchStores', branchStores)
    provide('settings', settings)

    onBeforeMount(async () => {
      loading.init = true
      getAssetsData()
      await getConfigData()
      initSettings(configData.value)
      loading.init = false
    })
    onMounted(async () => {
      await nextTick()
      loaded.value = true
    })
    return {
      modal,
      loaded,
      goBack,
      configData,
      submitPayload,
      settings,
      assetsData,
      formRefList,
      onSubmit,
      onCancel,
      loading,
      onEnableChange,
      onConfirmSubmit,
    }
  },
})

</script>

<style lang="postcss" scoped>
.edit-entry-control-time-and-price-settings {
  @apply flex flex-col gap-[30px];
}
</style>
