<template>
  <div>
    <BindingArea @edit="modal.selector = true">
      <div v-show="!displayData">尚未編輯</div>
      <div v-show="displayData">{{ displayData }}</div>
    </BindingArea>

    <AssetsOvertimeExitBindingModal
      v-if="modal.selector"
      :activeAssets="activeAssets"
      :settings="settings"
      @close="modal.selector = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { computed, defineComponent, inject, reactive } from 'vue'
import AssetsOvertimeExitBindingModal from './AssetsOvertimeExitBindingModal.vue'
import BindingArea from './BindingArea.vue'
import { useVModel } from '@vueuse/core'
import { find, get, join, map } from 'lodash'

export default defineComponent({
  name: 'AssetsOvertimeExitBindingArea',
  components: {
    AssetsOvertimeExitBindingModal,
    BindingArea,
  },
  props: {
    activeAssets: {
      type: Object,
      default: () => ({
        punchCardIds: [],
        pointCardIds: [],
      }),
    },
    settings: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const assetsData = inject('assetsData')
    const syncSettings = useVModel(props, 'settings', emit)
    const modal = reactive({
      selector: false,
    })
    const displayData = computed(() => {
      const data = []
      for (const setting of props.settings) {
        if (setting.enable) {
          const type = setting.type
          const itemName = get(find(assetsData[type], { id: setting.originId }), 'name')
          data.push({
            type: type === 'punchCard' ? '次卡' : '點數卡',
            name: itemName,
            allowMinutes: setting.setting.allowMinutes,
            allowRule: setting.setting.allowRule,
            enable: setting.enable,
          })
        }
      }
      return join(map(data, (item) => {
        if (item.allowMinutes === 'allowForever') return `${item.type} ${item.name} ( 無論超時多久均可出場 )`
        else return `${item.type} ${item.name} ( 允許超時 ${item.allowMinutes} 分鐘 )`
      }), '、')
    })

    const onConfirm = (data) => {
      syncSettings.value = data
    }
    return { displayData, modal, onConfirm, syncSettings }
  },
})

</script>

<style scoped lang="postcss">
</style>
